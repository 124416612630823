@import '../../../../../globals/variables.scss';

.footer {
  // margin-top: 40px;
  background-color: $primary;
  padding: 20px 20px;
  color: white;
  //   border-top: 1px solid lightgrey;
  img {
    max-width: 120px;
  }
  p {
    color: white;
    font-size: 13px;
  }

  .innerFooter {
    width: 100%;
    max-width: $wrapperMaxWidth;
    margin: 0 auto;
    p {
      font-weight: 400;
      letter-spacing: 0.5px;
      color: lightgrey;
      a {
        color: white;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
  .footerbottom {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: 400;
      a {
        text-decoration: none;
        span {
          font-weight: 600;
        }
      }
    }
  }
}
