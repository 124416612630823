$primary: #4068a7;
$secondary: #364d61;

$darkgreenGradient: linear-gradient(90.08deg, #d5b891 1.32%, #a4713f 100.87%);
$wrapperMaxWidth: 1200px;
$timing: 1500ms;
$bigtiming: 1750ms;
$inputWidth: 100%;
$inputWidthMax: 900px;

$primaryFusion: #70bc96;
$secondaryFusion: #14261c;
$purpleFusion: #12001d;
$textColorFusion: #7a7a7a;
$borderWidth: 7px;
