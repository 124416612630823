@import '../../globals/variables.scss';

.points {
  background-color: $secondary;
  .innerPoints {
    display: flex;
    justify-content: center;
    > div {
      width: 20%;
      max-width: 120px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
}
