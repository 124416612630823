@import '../../globals/variables.scss';

@media screen and (max-width: 992px) {
  .banner {
    padding: 65px 0 25px;
    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
    }
    .bannerInner {
      position: relative;
      padding-top: 18px;
      h1,
      p {
        text-align: center;
        margin: 0;
        color: white;
        line-height: 1.4;
      }

      h1 {
        font-size: 22px;
      }
      p {
        margin: 20px 0 0;
        letter-spacing: 1px;
        font-weight: 700;
        font-size: 17px;
        color: whitesmoke;
        font-style: italic;
      }
    }
  }
}
