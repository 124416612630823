@import './variables.scss';

.pageContent {
  padding: 50px 0 0;
  margin: 0;
  @media screen and (max-width: 992px) {
    padding: 20px 0 0;
  }
  p,
  ul li,
  a {
    text-align: left;
    @media screen and (max-width: 992px) {
      font-size: 15px;
    }
  }
}
.underMaintance {
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  h1 {
    color: black;
    text-align: center;
    margin: 0;
    font-weight: 100;
  }
}

.previews {
  display: flex;
  padding-bottom: 70px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  embed {
    border: 1px solid grey;
    border-radius: 9px;
    overflow: hidden;
  }
  a {
    color: grey;
    font-weight: 400;
    text-decoration: none;
    font-style: italic;
    letter-spacing: 1px;
  }
}

.desktopStyle {
  display: block !important;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.desktopStyleFlex {
  display: flex !important;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.mobileStyle {
  display: none !important;

  @media screen and (max-width: 768px) {
    display: block !important;
  }
}

.mobileStyleFlex {
  display: none !important;

  @media screen and (max-width: 768px) {
    display: flex !important;
  }
}

.textWrapper {
  max-width: 800px;
  display: block;
  margin: 0 auto;
  p {
    line-height: 1.6;
    color: #444;
    font-weight: 700;
  }
  ul {
    padding: 0px;
    li {
      border-bottom: 1px solid $primary;
      padding: 5px 0;
      margin-bottom: 5px;
      list-style-type: none;
      line-height: 1.7;
      letter-spacing: 0.8px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}
