@import '../../globals/variables.scss';
.banner {
  // background-image: url('../../assets/foa-banner.jpg');
  background-image: url('../../assets/car-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  padding: 100px 0 60px;
  margin-bottom: 0;
  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }
  .bannerInner {
    position: relative;

    h1,
    p {
      text-align: center;
      margin: 0;
      color: white;
      line-height: 1.4;
    }
    h1 {
      font-size: 30px;
    }
    p {
      margin: 20px 0 0;
      letter-spacing: 1px;
      font-weight: 700;
      font-size: 17px;
      color: whitesmoke;
      font-style: italic;
    }
  }
}
