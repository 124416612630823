@import '../../globals/variables.scss';

.steps {
  padding: 30px 0;
  background-color: whitesmoke;
  text-align: center;
  border-bottom: 5px solid #364d61;
  border-top: 5px solid #364d61;
  margin-top: 50px;

  p {
    font-size: 15px;
    text-align: center;
    margin: 0 auto 5px;
    color: $primary;
    font-weight: 800;
  }
  h2 {
    font-size: 24px;
    padding: 0;
    letter-spacing: 1px;
    margin: 0 0 30px;
    font-weight: 400;
    color: #364d61;
    position: relative;
    padding-bottom: 20px;
    text-transform: uppercase;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 70px;
      background-color: #364d61;
      margin: 0 auto;
    }
  }
  .stepsContainer {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
    margin: 0 auto;
    > div {
      width: 30%;
      flex-shrink: 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        text-align: center;
        font-weight: 700;
        color: #364d61;
        margin-bottom: 0;
        vertical-align: middle;
        display: inline;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 12px;
        text-transform: uppercase;
        span {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          background-color: #364d61;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
          border: 2px solid #4068a7;
        }
      }

      img {
        width: 100%;
        max-height: 70px;
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-weight: 500;
    .stepsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > div {
        width: 100%;
        margin: 10px auto 20px;
        flex-shrink: 0;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
    padding: 30px 0;
    p {
    }
    h2 {
      font-size: 24px;
    }
    img {
      max-width: 70%;
    }
  }
}
