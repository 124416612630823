@import '../../../../../globals/variables.scss';

@media screen and (max-width: 992px) {
  .header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    background-color: white;
    z-index: 9999;
    padding: 10px 0;
    .innerHeader {
      padding: 0;
    }

    .initialsCircle {
      border-radius: 50%;
      border: 1px solid $primary;
      background-color: whitesmoke;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 22px;
        font-weight: 300;
      }
    }
    .portalUserInfo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .profileImgContainer {
        border: 1px solid grey;
        border-radius: 50%;
        margin-right: 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: translateY(5px);
        }
      }
    }
    .logo {
      a {
        width: 100%;
        justify-content: flex-end;
        img {
          margin: 0;
        }
      }
    }
  }
}
